<template>
  <fb-page>
    <fb-header slot="header"
               title="案件概要"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
      </fb-button>
    </fb-header>
    <div class="content-content">
      <div style="display: flex;align-items: center;justify-content: center;"
           v-if="loading">
        <van-loading />
      </div>
      <textarea class="content-text"
                v-model="content"
                @focus="DianJi"></textarea>
    </div>
    <div class='bottom-btn'>
      <span class='cancel-btn'
            @click="back">取消</span>
      <span class='save-btn'
            @click='save'>保存</span>
    </div>
  </fb-page>
</template>

<script>
import { caseBase } from '~api-config'
import Qs from 'qs'
export default {
  data() {
    return {
      caseId: '',
      content: '',
      userId: '',
      other: '',
      loading: false
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    save() {
      var data = {
        userId: parseInt(this.userId),
        caseId: parseInt(this.caseId),
        claimText: this.other,
        briefCaseText: this.content
      }
      this.$axios
        .put(
          caseBase + '/new-design/caseUserDocument/create',
          Qs.stringify(data)
        )
        .then(res => {
          if (res.data.code === 200) {
            this.$router.back()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    DianJi() {
      if (this.content === '无数据') {
        this.content = ''
      }
    }
  },
  created() {
    this.userId = this.$store.state.user.userInfo.id
    this.caseId = this.$route.params.caseId
    this.loading = true
    this.$axios
      .get(
        caseBase + '/new-design/caseUserDocument/queryByCaseId/' + this.caseId
      )
      .then(res => {
        if (res.data.data.caseUserDocument.length !== 0) {
          const textinfos = JSON.parse(
            res.data.data.caseUserDocument[0].documentData
          )[0]
          this.other = textinfos.suSongQingQiu_1.replace(/\^\^\^/g, '\n')
          this.content = textinfos.shiShiLiYou_1.replace(/\^\^\^/g, '\n')
        } else {
          this.content = '无数据'
          this.other = '无数据'
        }
        this.loading = false
      })
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  height: auto;
}
.top {
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  border-color: #eeeeee;
  height: 46px;
  line-height: 46px;
}
.icon-back {
  font-size: 15px;
}
.bottom-btn {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.bottom-btn > span {
  display: inline-block;
  width: 50%;
  height: 50px;
  font-size: 16px;
  font-family: PingFangSC;
  font-weight: normal;
  color: white;
  background-color: #4b9efb;
  line-height: 50px;
  text-align: center;
}
.cancel-btn {
  background-color: #dddddd !important;
}
.content-content {
  width: 92%;
  height: auto;
  margin: 13px auto;
}
.content-text {
  width: 100%;
  min-height: 250px;
  font-size: 15px;
  font-family: PingFangSC;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 23px;
  border: 0;
  white-space: pre-wrap;
}
.top > span {
  margin-left: 15px;
  font-size: 15px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
}
</style>
